<template>
  <ItemList
    @addItem="addOperationNature"
    @removeItem="removeOperationNature"
    :items="operationNatures"
    :isLoading="isLoadingList"
    noDataText="Nenhuma natureza de operação encontrada"
    label="Natureza de operação"
  />
</template>

<script>
export default {
  components: {
    ItemList: () => import('./ItemList.vue'),
  },
  data: () => ({
    operationNatures: [],
    isLoadingList: false,
  }),
  methods: {
    getNatures() {
      this.isLoadingList = true
      this.$api
        .get('operation_natures')
        .then((res) => {
          this.operationNatures = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingList = false
        })
    },
    addOperationNature(value) {
      this.$api
        .post('operation_natures/store', { name: value })
        .then((res) => {
          this.operationNatures.push(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeOperationNature(item) {
      this.$api
        .post('operation_nature/delete', item)
        .then(() => {
          let idx = this.operationNatures.indexOf(item)
          this.operationNatures.splice(idx, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    if (this.operationNatures.length === 0) {
      this.getNatures()
    }
  },
}
</script>

<style>
</style>